.playing {
  background: rgba(0, 0, 50);
  width: 5rem;
  height: 2rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem;
  box-sizing: border-box;
}

.playing__bar {
  display: inline-block;
  background: white;
  width: 30%;
  height: 100%;
  margin-left: 1.8px;
  animation: up-and-down 1.3s ease infinite alternate;
}

.playing__bar1 {
  height: 60%;
}

.playing__bar2 {
  height: 30%;
  animation-delay: -2.2s;
}

.playing__bar3 {
  height: 75%;
  animation-delay: -3.7s;
}
.playing__bar4 {
  height: 85%;
  animation-delay: -3.9s;
}
.playing__bar5 {
  height: 95%;
  animation-delay: -5s;
}

@keyframes up-and-down {
  10% {
    height: 30%;
  }

  30% {
    height: 100%;
  }

  60% {
    height: 50%;
  }

  80% {
    height: 75%;
  }

  100% {
    height: 60%;
  }
}
